body {
  background-color: #303030;
}

.title {
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 100px;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

button {
  background-color: #7289DA;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #677bc4;
}

